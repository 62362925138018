import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import MovieList from './components/MovieList';
import MoviePlayer from './components/MoviePlayer';
import Navbar from './components/Navbar';
import SearchBar from './components/SearchBar';
import moviesData from './data/moviesData';
import ReactPaginate from 'react-paginate';
import ActressList from './components/ActressList';
import ActressVideos from './components/ActressVideos';
import Overlay from './components/Overlay';
import './App.css';

const App = () => {
  const randomMoviesData = getRandomMovies(moviesData, moviesData.length);

  const [filteredMovies, setFilteredMovies] = useState(randomMoviesData);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [selectedActress, setSelectedActress] = useState('All');
  const moviesPerPage = 20;
  const navigate = useNavigate();
  const location = useLocation();
  const monetizationLink = "https://t.antj.link/246207/3785/0?bo=2753,2754,2755,2756&pyt=multi&po=6456&aff_sub5=SF_006OG000004lmDN";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  function getRandomMovies(movies, count) {
    const shuffled = movies.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = moviesData.filter(movie =>
      movie.title.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredMovies(filtered);
    setCurrentPage(0);
  };

  const handleHomeClick = () => {
    setCurrentPage(0);
    setFilteredMovies(randomMoviesData);
    setSelectedCategory('All');
    setSelectedGenre('All');
    setSelectedActress('All');
    navigate('/');
  };

  const handleRefreshMovies = () => {
    const newRandomMovies = getRandomMovies(moviesData, moviesData.length);
    setFilteredMovies(newRandomMovies);
    setCurrentPage(0);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    filterMovies(category, selectedGenre, selectedActress);
  };

  const handleGenreChange = (e) => {
    const genre = e.target.value;
    setSelectedGenre(genre);
    filterMovies(selectedCategory, genre, selectedActress);
  };

  const handleActressChange = (e) => {
    const actress = e.target.value;
    setSelectedActress(actress);
    filterMovies(selectedCategory, selectedGenre, actress);
  };

  const filterMovies = (category, genre, actress) => {
    const filtered = moviesData.filter(movie => {
      const categoryMatch = category === 'All' || movie.channel === category;

      // Split genres and match individually
      const genres = movie.genre.flatMap(g => g.split(',').map(genre => genre.trim()));
      const genreMatch = genre === 'All' || genres.includes(genre);

      // Split actresses and match individually
      const actresses = movie.stars.flatMap(a => a.split(',').map(actress => actress.trim()));
      const actressMatch = actress === 'All' || actresses.includes(actress);

      return categoryMatch && genreMatch && actressMatch;
    });
    setFilteredMovies(filtered);
    setCurrentPage(0);
  };

  const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);
  const currentMovies = filteredMovies.slice(
    currentPage * moviesPerPage,
    (currentPage + 1) * moviesPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Flatten and split genres and actresses into individual values
  const genres = [...new Set(moviesData.flatMap(movie => movie.genre.flatMap(g => g.split(',').map(genre => genre.trim()))))].filter(Boolean);
  const actresses = [...new Set(moviesData.flatMap(movie => movie.stars.flatMap(a => a.split(',').map(actress => actress.trim()))))].filter(Boolean);
  const categories = [...new Set(moviesData.map(movie => movie.channel))].filter(Boolean);

  return (
    <div className="app">
      {/* <Overlay monetizationLink={monetizationLink} /> */}
      <Navbar onHomeClick={handleHomeClick} />
      <div className="content">
        <div className="button-container">
          <button className="random-button" onClick={handleRefreshMovies}>Discover New Series</button>
        </div>
        <SearchBar onSearch={handleSearch} />
        {(location.pathname === '/') && (<div className="filter-container">
          <div className="dropdown-container" style={{"width":"20%"}}>
            <label htmlFor="category-dropdown">Category: </label>
            <select id="category-dropdown" value={selectedCategory} onChange={handleCategoryChange}>
              <option value="All">All</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="dropdown-container" style={{"width":"20%"}}>
            <label htmlFor="genre-dropdown">Genre: </label>
            <select id="genre-dropdown" value={selectedGenre} onChange={handleGenreChange}>
              <option value="All">All</option>
              {genres.map(genre => (
                <option key={genre} value={genre}>{genre}</option>
              ))}
            </select>
          </div>
          <div className="dropdown-container" style={{"width":"20%"}}>
            <label htmlFor="actress-dropdown">Actress: </label>
            <select id="actress-dropdown" value={selectedActress} onChange={handleActressChange}>
              <option value="All">All</option>
              {actresses.map(actress => (
                <option key={actress} value={actress}>{actress}</option>
              ))}
            </select>
          </div>
        </div>)}
        <Routes>
          <Route
            path="/"
            element={<MovieList movies={currentMovies} onSelectMovie={handleSelectMovie} />}
          />
          <Route
            path="/actresses"
            element={<ActressList movies={moviesData} onSelectMovie={handleSelectMovie} />}
          />
          <Route path="/actress/:name" element={<ActressVideos movies={moviesData} onSelectMovie={handleSelectMovie} />} />
          <Route
            path="/movie/:title"
            element={<MoviePlayer movies={moviesData} />}
          />
        </Routes>
        {(location.pathname === '/' || location.pathname.startsWith('/movie/')) && (
          <div className="pagination-container">
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item previous"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              forcePage={currentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
