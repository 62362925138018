import React, { useEffect } from 'react';
import MovieCard from './MovieCard';
import './MovieList.css';

const MovieList = ({ movies, onSelectMovie }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="movie-list">
      {/* Support Notification Card */}
      <div className="notification-card">
        <h3>📢 Join Our New Telegram Channel!</h3>
        <p>
          Our previous channel has been removed, Please🙏 show support to new channel! Join our new Telegram channel and get best videos ever!
        </p>
        <a href="https://t.me/+5vrelzi5pOo1ZGE1" target="_blank" rel="noopener noreferrer" className="join-button">
          Join & Support Us
        </a>
      </div>

      {/* Movie Cards */}
      {movies.map(movie => (
        <MovieCard key={movie.title} movie={movie} onSelectMovie={onSelectMovie} />
      ))}
    </div>
  );
};

export default MovieList;
